.dsh-content {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #00000000;
    align-content: flex-start;
}

.dsh-navbar {
    background-color: #FFF;
    height: 5em;
    box-shadow: 0em 0.4em 0.8em rgba(0, 0, 0, 0.2);
    padding: 0em 2em;
    z-index: 10;
    position: relative;
}

.dsh-menu {
    font-size: 2em;
    cursor: pointer;
    transition: .2s;
}

.dsh-menu:hover {
    color: var(--color01);
}

.dsh-div-logo {
    position: absolute;
    top: 2.2em;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dsh-logo {
    width: 9em;
    margin-top: 0.4em;
}

.dsh-head-avatar {
    width: 3em !important;
    height: 3em !important;
    margin-right: 0.6em;
}

.dsh-head-typeuser {
    color: var(--color01);
    font-size: 0.9em;
    font-weight: 700;
}

.dsh-head-nameuser {
    margin-top: -0.2em;
    font-size: 1.1em;
}

.dsh-head-user {
    cursor: pointer;
}

.dsh-page {
    flex-wrap: nowrap;
    z-index: -2 !important;
}

.dsh-page-sidebar {
    background-color: #FFF;
    height: calc(100vh - 5em);
    box-shadow: 0.4em 0em 0.8em rgba(0, 0, 0, 0.1);
    margin-left: -20em;
    transition: .2s;
    padding: 1em;
    overflow-y: auto;
}

.dsh-page-sidebar.active {
    margin-left: 0em;
}

.list-check {
    color: #00000073;
    cursor: pointer;
    transition: 0.2s;
}

.list-check:hover {
    color: var(--color01);
}

.ord-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: var(--color01);
    margin-bottom: -5px;
}

.ord-value {
    font-size: 18px;
}

.button-report {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.8em 1em;
    width: 140px;
    height: 132px;
    cursor: pointer;
    border: 1px solid rgba(69, 69, 69, 0.3);
    transition: 0.2s;
    border-radius: 8px;
}

.button-report:hover {
    border-color: var(--color01);
}

.button-report.disabled {
    background-color: #00000014;
    cursor: not-allowed;
    color:#525252
}

.button-report.disabled:hover {
    border: 1px solid rgba(69, 69, 69, 0.3);
}

.button-report-icon {
    font-size: 36px;
}

.button-report-text {
    font-weight: 500;
    text-align: center;
}

@media (max-width: 1100px) {

    .dsh-navbar {
        padding: 0em 1em;
    }

    .dsh-head-avatar {
        margin-right: 0em;
    }

    .dsh-head-text {
        display: none;
    }

    .dsh-page-sidebar {
        position: absolute;
        width: 20em;
        z-index: 8;
        margin-left: 0em;
    }

    .dsh-page-sidebar.active {
        margin-left: -20em;
    }

    .list-service {
        justify-content: flex-start;
    }

}