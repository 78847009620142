.crd-adm {
    padding: 1em;
    box-shadow: 0px 0px 2em rgba(0, 0, 0, 0.1);
    border-radius: 0.4em;
    background-color: #FFF;
}

.crd-adm-head-col {
    display: flex;
    align-items: flex-end;
}

.crd-adm-head-title {
    font-size: 1.3em;
    font-weight: 600;
}

.crd-adm-head-subtitle {
    color: rgba(0, 0, 0, 0.6);
    margin-left: 0.3em;
}