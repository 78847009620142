.st-row {
    width: 100vw;
    height: 100vh;
    background-color: var(--color04);
    flex-direction: column;
    flex-wrap: nowrap
}

.st-img {
    width: 14em;
}

.st-text {
    font-size: 1.1em;
}

.st-text a {
    color: black;
    font-weight: 600;
}

.st-text a:hover {
    color: var(--color01) !important;
}

.st-login, .st-cadastrese {
    width: 20em;
    padding: 1em;
    background-color: #FFF;
    box-shadow: 0px 0px 5em rgba(0, 0, 0, 0.1);
    margin-bottom: 1em;
    border-radius: 0.8em;
}

.st-cadastrese {
    width: 90vw;
    max-height: 700px;
    margin-top: 10px;
    overflow: hidden;
    overflow-y: auto;
}

.st-title {
    text-align: center;
    font-size: 1.4em;
    font-weight: 600;
}

.st-text {
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 1em;
    transition: 0.2s;
}

.st-text-forgot {
    text-align: center;
    font-size: 1.1em;
    margin-top: 1em;
    transition: 0.2s;
}

.st-text-forgot a {
    color: black;
    font-weight: 600;
}

.st-text-forgot a:hover {
    color: var(--color01) !important;
}
