
.page-default-page {
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 6em;
    align-content: flex-start;
}

.page-default-button {
    background-color: transparent !important;
    color: #ffffffab !important;
    font-size: 1.4em;
    padding: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-default-button:hover, .page-default-button:focus {
    color: #fff !important;
}

.page-default-link {
    cursor: pointer;
    transition: .2s;
}

.page-default-link:hover {
    color: var(--color01);
}

.page-default-title {
    font-size: 1.6em;
    font-weight: 600;
    color: var(--color01);
}

.page-default-text {
    text-align: center;
}

.page-default-text b, .page-default-text a, .page-default-text a:hover {
    color: var(--color01);
}