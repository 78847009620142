:root {
  --color01: #780403;
  --color02: #0000004b;
  --color03: #5f0200;
  --color04: #f6f9ff;
}

* {
  font-family: 'Open sans';
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-carousel li button {
  background-color: var(--color01) !important;
}

.btn-primary {
  background-color: var(--color01);
  color: #FFF;
  box-shadow: none;
  border: none;
  transition: 0.2s;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: var(--color03) !important;
  color: #fff !important;
}

.btn-default {
  border: 1px solid var(--color01);
  color: var(--color01);
  box-shadow: none;
  transition: 0.2s;
}

.btn-default:hover, .btn-default:focus {
  border-color: var(--color03) !important;
  color: var(--color03) !important;
}

@media (min-width: 900px) {
  ::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
      background-color: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px !important;
      border-radius: 10px !important;
      background-color: rgba(0, 0, 0, 0.4) !important;
  }
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled],
.ant-select-disabled .ant-select-selector,
.ant-input-number-disabled,
.ant-checkbox-wrapper-disabled span,
.ant-radio-wrapper-disabled span {
  color: rgba(0, 0, 0, 0.7) !important
}

.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.7) !important;
}

.rdw-block-wrapper,
.rdw-fontsize-wrapper,
.rdw-fontfamily-wrapper,
.rdw-colorpicker-wrapper,
.rdw-link-wrapper,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.leaflet-bottom,
.leaflet-control-container {
  display: none !important;
}

.ant-carousel .slick-dots {
  z-index: 0 !important;
}