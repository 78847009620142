.mvv {
    padding: 0em 2em;
}

.mvv-icon {
    font-size: 3em;
    color: var(--color01);
}

.mvv-title {
    text-align: center;
    font-weight: 700;
    font-size: 1.4em;
    margin: 0.8em 0em;
}

.mvv-text {
    font-size: 1.1em;
    text-align: justify;
}