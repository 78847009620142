.mnu-content {
    padding: 0.6em;
    border-radius: 0.4em;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .2s;
    margin-bottom: 0.6em;
}

.mnu-content.active, .mnu-content:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.mnu-text {
    font-weight: 600;
    margin-left: 0.6em;
}

.mnu-icon {
    font-size: 1.2em;
    height: 1.2em;
}

.mnu-content.active .mnu-icon {
    color: var(--color01);
}