.kpi-icon {
    background-color: aqua;
    border-radius: 100%;
    height: 2em;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4em;
}

.kpi-value {
    font-size: 2.2em;
    font-weight: 600;
}