.srv {
    padding: 0.8em;
    box-shadow: 0px 0px 5em rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    min-height: 36em;
}

.srv-title {
    font-size: 1.4em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
}

.srv-img {
    width: 40%;
}

.srv-text {
    text-align: justify;
    font-size: 1.1em;
}

.srv-subtitle {
    font-weight: 700;
    margin-top: 0.6em;
    font-size: 1.2em;
}