.lp-hd {
    height: 2.4em;
    padding: 0.2em 6em;
    background-color: rgba(0, 0, 0, 0.02);
}

.lp-hd-icon {
    font-size: 1.2em;
    color: var(--color01);
}

.lp-hd-text {
    font-size: 1.1em;
}

.lp-hd-link {
    font-size: 1.3em;
    color: var(--color02);
    transition: 0.2s;
    cursor: pointer;
}

.lp-hd-link:hover {
    color: var(--color01);
}

.lp-nav-affix {
    z-index: 9999 !important;
}

.lp-nav {
    height: 5em;
    padding: 0.2em 6em;
    background-color: #FFF;
    width: 100%;
    z-index: 9999 !important;
}

.lp-nav-logo {
    width: 7em;
}

.lp-nav-btn {
    border: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.6);
    transition: 0.2s;
}

.lp-nav-btn:hover, .lp-nav-btn:focus, .lp-nav-btn.active {
    color: var(--color01) !important;
    box-shadow: none !important;
}

.lp-nav-btn.lp-nav-btn-primary {
    border: 1px solid var(--color01);
    color: var(--color01);
}

.lp-nav-btn.lp-nav-btn-primary:hover {
    border-color: var(--color01) !important;
    background-color: var(--color01) !important;
    color: #FFF !important;
}

.lp-backtop, .lp-backtop .ant-float-btn-body {
    background-color: var(--color01);
}
.lp-backtop .ant-float-btn-body .ant-float-btn-icon {
    color: #FFF !important;
}

.lp-backtop:hover .ant-float-btn-body {
    background-color: var(--color03) !important;
}

.lp-mvv {
    padding: 4em 6em;
    background-color: rgba(0, 0, 0, 0.02);
}

.lp-sobrenos {
    padding: 6em;
}

.lp-rw-title {
    font-size: 2.6em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    text-align: center;
}

.lp-rw-title::before {
    content: "";
    position: absolute;
    display: block;
    width: 4em;
    height: 1px;
    background: #ddd;
    bottom: -10px;
    left: calc(50% - 2em);
}

.lp-rw-title::after {
    content: "";
    position: absolute;
    display: block;
    width: 1em;
    height: 3px;
    background: var(--color01);
    bottom: -11px;
    left: calc(50% - 0.5em);
}

.lp-rw-text {
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.1em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.lp-sobrenos-img {
    width: 100%;
    border-radius: 1em;
}

.lp-sobrenos-title {
    font-weight: 700;
    font-size: 2em;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0.8em;
}

.lp-sobrenos-text {
    font-size: 1.2em;
    margin-bottom: 0.8em;
    text-align: justify;
}

.lp-cnt-row {
    flex-wrap: nowrap;
}

.lp-cnt-icon-div {
    background-color: #ddd;
    border-radius: 50em;
    width: 3.6em;
    height: 3.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
}

.lp-cnt-icon {
    font-size: 1.6em;
    color: var(--color01);
    transition: 0.2s;
}

.lp-cnt-row:hover .lp-cnt-icon-div {
    background-color: var(--color01);
}

.lp-cnt-row:hover .lp-cnt-icon {
    color: #FFF;
}

.lp-cnt-title {
    font-weight: 700;
    font-size: 1.2em;
}

.lp-cnt-row + .lp-cnt-row {
    margin-top: 1em;
}

.lp-footer {
    padding: 4em;
    background-color: black;
    flex-direction: column;
}

.lp-footer-img {
    width: 14em;
}

.lp-footer-button {
    width: 3em;
    height: 3em;
    background-color: var(--color01);
    border-radius: 50em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
}

.lp-footer-button:hover, .lp-footer-button:focus {
    background-color: var(--color03);
}

.lp-footer-icon {
    color: #FFF !important;
    font-size: 1.6em;
}

.lp-footer-text {
    margin-top: 1em;
    color: #FFF !important;
    text-align: center;
}

.lp-footer-text a {
    color: #FFF !important;
}

.lp-nav-btn-menu {
    font-size: 2em;
    width: 1.8em;
    height: 1.8em;
    display: none;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
}

.lp-nav-btn-menu:hover, .lp-nav-btn-menu:focus {
    color: var(--color01) !important;
}

.gallery-card {
    width: 102px;
    height: 102px;
    border: solid 1px #d9d9d9;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-card img {
    max-width: 92px;
    max-height: 92px;
}

.gallery-filter {
    position: absolute;
    width: 92px;
    height: 92px;
    background-color: black;
    opacity: 0;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-icons {
    position: absolute;
    width: 92px;
    height: 92px;
    opacity: 0;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-card:hover .gallery-filter {
    opacity: 0.6;

}
.gallery-card:hover .gallery-icons {
    opacity: 1;
}

.gallery-icon {
    font-size: 16px;
    color: #FFF;
}

.image-coord {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(42, 42, 42, 0.9);
    width: calc(100% - 40px);
    height: 120px;
    border-radius: 8px;
    z-index: 9999;
}

.image-coord-date {
    font-size: 18px;
    color: #FFF;
    text-align: left;
}

.image-coord-resp {
    font-weight: 300;
    color: #FFF;
    text-align: left;
}

.image-coord-city {
    color: #FFF;
    text-align: left;
    font-weight: 700;
    font-size: 12px;
}

.image-coord-coord {
    color: #FFF;
    text-align: left;
}

@media (max-width: 1100px) {

    .lp-hd .lp-hd-text {
        display: none;
    }

    .lp-nav, .lp-hd {
        padding: 0.2em 1em;
    }

    .lp-mvv {
        padding: 2em 0em;
    }

    .lp-sobrenos {
        padding: 1.4em;
    }

    .lp-footer{
        padding: 4em 1.4em;
    }

    .lp-nav-btn-menu {
        display: flex;
    }

    .lp-nav-col {
        display: none;
    }

}